import axios from "axios";

const instance = axios.create({
  baseURL: "http://localhost:5000/api", 
  // baseURL: "https://server.inttleisure.com/api", 
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const login = async (formData) => {
  try {
    const response = await instance.post("/login", formData);
    localStorage.setItem("authToken", response.data.token);
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: error.response?.status, message: error.response?.data.message };
  }
};

export const logout = async () => {
  localStorage.removeItem("authToken");
  return { status: 200, message: "Logged out successfully." };
};

export const checkSession = async () => {
  try {
    const response = await instance.get("/session");
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: error.response?.status, message: error.response?.data.message };
  }
};
