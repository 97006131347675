import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkSession } from "../api/adminLogin";

const useAuthNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );

  const verifySession = async () => {
    try {
      const response = await checkSession();
      const isValid = response.status === 200;
      
      setIsAuthenticated(isValid);
      if (isValid) {
        localStorage.setItem("isAuthenticated", "true");
      } else {
        localStorage.removeItem("isAuthenticated");
      }
      
      return isValid;
    } catch (error) {
      console.error('Session verification failed:', error);
      setIsAuthenticated(false);
      localStorage.removeItem("isAuthenticated");
      return false;
    }
  };

  useEffect(() => {
    const isAdminRoute = location.pathname === "/admin" || location.pathname === "/admin/dashboard";
    
    if (isAdminRoute) {
      // Using an IIFE to handle async operation inside useEffect
      (async () => {
        const isValid = await verifySession();
        
        if (isValid && location.pathname === "/admin") {
          navigate("/admin/dashboard");
        } else if (!isValid && location.pathname === "/admin/dashboard") {
          navigate("/admin");
        }
      })();
    }
  }, [location.pathname, navigate]);

  return { isAuthenticated, setIsAuthenticated };
};

export default useAuthNavigation;